<template>
  <div class="d-flex flex-column pa-12 enrollment-list">
    <!-- Tabs -->
    <v-tabs v-model="tab" color="secundary">
      <v-tabs-slider color="primary" />
      <v-tab v-for="{ id, name } in tabs" :key="id" class="text-capitalize" v-text="name" />
    </v-tabs>
    <v-divider />
    <!-- Search -->
    <v-row v-if="enrollments.length" class="tabs-toolbar align-self-end justify-start mx-0 my-6">
      <v-col v-if="requiresPayment && tab === 0">
        <v-row>
          <!-- <v-select
            v-model="searchCategory"
            :items="uniqueCategories"
            :label="$t('common.categories')"
            append-icon="mdi-chevron-down"
          /> -->
          <v-select
            v-model="searchStatus"
            :items="uniqueStatuses"
            item-text="name"
            item-value="value"
            :label="$t('common.status')"
            append-icon="mdi-chevron-down"
            dense
            hide-details="auto"
            class="pr-4"
          />
        </v-row>
      </v-col>
      <v-col :cols="requiresPayment && tab === 0 ? 8 : 12">
        <v-row>
          <export-to-csv
            :file-name-preffix="`${project.name} - Enrollments`"
            action-name="projectUsers/getEnrollmentsCsv"
            :action-params="{ organizationId, projectId }"
          />
          <span class="pr-4" />
          <v-text-field
            v-model="searchText"
            :label="$t('common.search')"
            prepend-inner-icon="search"
            tag="span"
            outlined
            dense
            hide-details
          />
        </v-row>
      </v-col>
    </v-row>
    <!-- Table -->
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="{ id, content } in tabs" :key="id">
        <v-data-table v-if="content.length"
                      :headers="headers"
                      :items="content"
                      :sort-by="['date']"
                      :sort-desc="true"
                      :search="searchText"
                      dense
                      class="mt-12"
                      :class="hasFormFeatures && 'table--has-pointer'"
                      @click:row="showForm"
        >
          <template #[`item.category`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  class="d-inline-block text-truncate"
                  style="max-width: 4rem;"
                  v-on="on"
                  v-text="item.category"
                />
              </template>
              <span v-text="item.category" />
            </v-tooltip>
          </template>
          <template #[`item.status`]="{ item }">
            <v-chip
              v-if="item.status === 'pendingApproval'"
              outlined
              label
              color="##F6F6F6"
              style="min-width: 5rem;"
            >
              {{ $t('common.pending') }}
            </v-chip>
            <v-chip
              v-else-if="item.status === 'pendingPayment'"
              outlined
              label
              color="pending"
              style="min-width: 5rem;"
            >
              {{ $t('project.enrollments.pendingPayment') }}
            </v-chip>
            <v-chip
              v-else-if="item.status === 'rejected'"
              outlined
              label
              color="error"
              style="min-width: 5rem;"
            >
              {{ $t('common.rejected') }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-if="item.status === 'pendingApproval'"
                  v-bind="attrs"
                  icon
                  color="success"
                  style="justify-content: start !important;"
                  v-on="on"
                  @click.stop.prevent="currentEnrollment = item; approve(item)"
                >
                  <v-icon style="margin: 0 auto;">
                    check_circle_outline
                  </v-icon>
                </v-btn>
              </template>
              <span v-text="requiresPayment
                ? $t('project.enrollments.sendPaymentRequest')
                : $t('project.enrollments.acceptEnrollmentRequest')"
              />
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  color="error"
                  style="justify-content: start !important;"
                  v-on="on"
                  @click.stop.prevent="$confirm(requiresPayment
                    ? $t('project.enrollments.confirmRejectPayment')
                    : $t('project.enrollments.confirmReject'))
                    .then(confirm => confirm && reject(item))
                  "
                >
                  <v-icon style="margin: 0 auto;">
                    highlight_off
                  </v-icon>
                </v-btn>
              </template>
              <span v-text="item.status === 'pendingApproval'
                ? requiresPayment ? $t('project.enrollments.rejectPaymentRequest') : $t('project.enrollments.rejectEnrollmentRequest')
                : requiresPayment ? $t('project.enrollments.deletePaymentRequest') : $t('project.enrollments.deleteEnrollmentRequest')"
              />
            </v-tooltip>
          </template>
        </v-data-table>
        <empty-list v-else :content="$t('project.sections.enrollments')" />
      </v-tab-item>
    </v-tabs-items>
    <dynamic-form-dialog
      v-if="project.features.form && dynamicFormDialog && clickedData"
      :clicked-data="clickedData"
      @close="dynamicFormDialog = false"
    />
    <request-payment-enrollment-dialog
      v-if="showRequestPaytmentEnrollmentDialog"
      @closeDialog="showRequestPaytmentEnrollmentDialog=false" @requestEnrollment="onRequestEnrollmentWithPayment"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import i18nCountries from '@/utils/mixins/i18nCountries'

export default {
  name: 'EnrollmentList',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
    DynamicFormDialog: () => import('@/modules/dynamicForm/DynamicFormDialog'),
    ExportToCsv: () => import('@/components/ExportToCsv'),
    RequestPaymentEnrollmentDialog: () => import('@/modules/project/RequestPaymentEnrollmentDialog'),
  },
  mixins: [i18nCountries],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
  },
  data() {
    return {
      searchText: null,
      searchStatus: null,
      tab: null,
      enrollments: [],
      clickedData: null,
      dynamicFormDialog: false,
      currentEnrollment: null,
      showRequestPaytmentEnrollmentDialog: false,
    }
  },
  computed: {
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters({ rawEnrollments: 'project/enrollments' }),
    ...mapGetters({ isAcademy: 'project/isAcademy' }),
    ...mapGetters({ categories: 'academy/categories' }),
    ...mapGetters({ hasFormFeatures: 'project/hasFormFeatures' }),
    requiresPayment: ({ project }) => project?.features?.price && project?.pricing?.priceOptions?.length,
    headers({ tab, requiresPayment, isAcademy }) {
      return [
        { text: this.$t('user.fields.name'), value: 'name' },
        ...(isAcademy ? [{ text: this.$t('common.category'), value: 'category' }] : []),
        { text: this.$t('user.fields.birthdate'), value: 'birthdate', filterable: false },
        { text: this.$t('project.enrollments.applicantEmail'), value: 'applicantEmail' },
        { text: this.$t('project.enrollments.requestDate'), value: 'date', filterable: false },
        ...(!isAcademy ? [{ text: this.$t('user.fields.country'), value: 'country', filterable: false }] : []),
        ...(requiresPayment && tab === 0 ? [{ text: this.$t('project.enrollments.enrollmentStatus'), value: 'status' }] : []),
        ...(tab === 0 ? [{ text: this.$t('common.actions'), value: 'actions', filterable: false, sortable: false }] : []),
      ]
    },
    filteredEnrollments({ enrollments, searchStatus }) {
      const activeRequests = enrollments.filter(enroll => ['pendingApproval', 'pendingPayment'].includes(enroll.status) || !enroll.status)
      if (searchStatus) {
        return activeRequests.filter(request => request.status === searchStatus)
      }
      return activeRequests
    },
    tabs({ enrollments, filteredEnrollments }) {
      return [
        {
          id: 'pending',
          name: this.$t('filters.pending'),
          content: filteredEnrollments,
        },
        {
          id: 'rejected',
          name: this.$t('filters.rejected'),
          content: enrollments.filter(enroll => enroll.status === 'rejected'),
        },
      ]
    },
    uniqueStatuses({ enrollments }) {
      return [{ value: null, name: this.$t('common.all') }, ...enrollments
        .map(i => i.status)
        .filter((item, i, ar) => ar.indexOf(item) === i)
        .map(status => ({ value: status, name: this.$t(`project.enrollments.${status}`) }))]
    },
  },
  watch: {
    rawEnrollments: {
      immediate: true,
      async handler(enrollments) {
        this.$store.commit('loader/show')
        this.enrollments = await Promise.all(enrollments.map(async enroll => {
          const user = await this.$store.dispatch('user/read', enroll.userId)
          return {
            ...enroll,
            name: `${user.firstName} ${user.lastName}`,
            country: user.country ? this.countries[user.country] : '-',
            ...(this.isAcademy && ({ category: this.categories?.find(category => category.id === enroll.categoryId)?.name })),
            birthdate: `${user.birthdate.year}-${user.birthdate.month}-${user.birthdate.day}`,
            date: DateTime.fromSeconds(enroll.createdAt.seconds).toISODate(),
            applicantEmail: user.email,
          }
        }))
        this.$store.commit('loader/hide')
      },
    },
  },
  methods: {
    approve() {
      const { organizationId, projectId } = this
      const { id: enrollmentId } = this.currentEnrollment
      if (this.requiresPayment) {
        this.showRequestPaytmentEnrollmentDialog = true
      } else {
        this.runAsync(() => this.$store.dispatch('project/approveEnrollment', { organizationId, projectId, enrollmentId }))
      }
    },
    async onRequestEnrollmentWithPayment(data) {
      this.showRequestPaytmentEnrollmentDialog = false
      const { organizationId, projectId } = this
      await this.runAsync(() => this.$store.dispatch(
        'project/requestPayment',
        { organizationId, projectId, enrollment: this.currentEnrollment, discount: data },
      ))
    },
    reject(enrollment) {
      const { organizationId, projectId } = this
      const { id: enrollmentId, status } = enrollment
      if (status === 'pendingApproval') {
        this.runAsync(() => this.$store.dispatch('project/rejectEnrollment', { organizationId, projectId, enrollmentId }))
      } else if (status === 'pendingPayment') {
        // reverse status
        this.runAsync(() => this.$store.dispatch(
          'project/unrequestPayment',
          { organizationId, projectId, enrollment },
        ))
      }
    },
    showForm(evt) {
      if (evt.info) {
        const { id, parentId, info: { form }, category } = evt
        this.clickedData = {
          id,
          parentId,
          form,
          category,
        }
        this.dynamicFormDialog = true
      }
    },
  },
}
</script>
